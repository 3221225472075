import API from '../../'

import 'firebase/auth'

let auth = null;
let isPersistent = false;

const init = () => {
  // init auth class
  auth = API.firebase.auth()
  // set auth to persistent
  !Auth.isPersistent && setPersistent()
}

const stateListener = (observer, onError) => {
  auth.onAuthStateChanged(observer, onError)
}

const setPersistent = async () => {
  try {
    await auth.setPersistence(API.firebase.auth.Auth.Persistence.LOCAL)
    isPersistent = true
    console.info('firebase Auth is persistent');
  } catch (err) {
    console.error(err)
  }
}

const logOut = async () => {
  try {
    await auth.signOut()
  } catch(err) {
    console.error(err)
  }
  return true
}

const getCurrentUser = () => {
  return auth.currentUser
}

const listenToSigningIn = async () => {
  const result = auth.getRedirectResult()
  console.log(result);
  // if (result.credential) {
  //   // This gives you a Google Access Token.
  //   var token = result.credential.accessToken;
  // }
  // var user = result.user;
}

const googleSignIn = async () => {
  const firebase = API.firebase
  const analytics = API.analytics
  analytics.logEvent('login', {method: 'Google'})
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  //auth.signInWithRedirect(provider);
  auth.signInWithPopup(provider)
}

const Auth = {
  isPersistent,
  auth,
  init,
  logOut,
  googleSignIn,
  setPersistent,
  stateListener,
  getCurrentUser,
  listenToSigningIn,
}

!Auth.auth && Auth.init()

export default Auth
